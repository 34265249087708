import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { media } from '../../utils/media-queries';

const PostPrev = ({ slug, src, title, year, alt }) => (
  <Link to={`/${slug}/`}>
    <StyledPostPrev className="hover">
      <Img fluid={src} alt={alt} />
      <div className="text">
        {year && <p className="headline">{`(${year})`}</p>}
        <h3>{title}</h3>
      </div>
    </StyledPostPrev>
  </Link>
);

const StyledPostPrev = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-S);
  align-items: center;
  text-align: center;

  .gatsby-image-wrapper {
    border: var(--border-small);
    width: 15vw;
    height: 9vw;
    margin-bottom: var(--spacing-XXS);
  }

  h3 {
    font-family: var(--font-1);
  }

  p {
    font-family: var(--font-3);
  }

  @media ${media.M} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;

    .gatsby-image-wrapper {
      width: 6vw;
      height: 4vw;
      margin-bottom: 0;
    }

    .text {
      display: flex;
      align-items: center;
      padding-left: var(--spacing-XXS);

      h3 {
        font-size: var(--headline);
        padding-left: var(--spacing-XXS);
      }
    }
  }
`;

PostPrev.propTypes = {
  src: PropTypes.object,
  slug: PropTypes.string,
  title: PropTypes.string,
  year: PropTypes.string,
  alt: PropTypes.string,
};

export default PostPrev;
