import { motion } from 'framer-motion';
import React, { useState } from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { useMouseMove } from '../../hooks/useMouseMove';
import { ease } from '../../utils/easing';

const ProjectPrev = ({ title, src, style, slug }) => {
  const [showImg, setShowImg] = useState();
  const { position, movement, direction } = useMouseMove();
  const rotate = movement.x / 10;

  return (
    <div style={style}>
      <h2
        className="hover"
        onMouseEnter={() => setShowImg(true)}
        onMouseLeave={() => setShowImg(false)}
      >
        <Link to={`/${slug}`}>{title}</Link>
      </h2>
      <motion.div
        className="preview-img"
        initial={{ opacity: 0 }}
        animate={{
          left: position.x - 600,
          top: position.y - 800,
          opacity: showImg ? 1 : 0,
        }}
        transition={{ duration: 0.6, ease: ease.outSmooth }}
      >
        <motion.figure
          animate={{
            rotate: direction === 'right' ? -rotate : rotate,
          }}
          transition={{ duration: 0.2, ease: 'linear' }}
        >
          <Img fluid={src} />
        </motion.figure>
      </motion.div>
    </div>
  );
};

export default ProjectPrev;
