import { graphql, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { AnimatePresence, motion } from 'framer-motion';
import { LayoutContext } from '../components/organisms/layoutProvider';
import { media } from '../utils/media-queries';
import { isEven } from '../utils/evenodd';
import ProjectPrev from '../components/atoms/projectPrev';
import SEO from '../components/organisms/seo';
import { ease } from '../utils/easing';
import Fade from '../components/atoms/fade';
import Float from '../components/atoms/float';

export const convertTypes = (type) => {
  switch (type) {
    case 'artdirection':
      return 'Art Direction';
    case 'creaturedesign':
      return 'Creature Design';
    case 'illustration':
      return 'Illustration';
    case 'immersive':
      return 'Immersive';
    case 'installation':
      return 'Installation';
    case 'performance':
      return 'Performance';
    case 'setdesign':
      return 'Set-Design';
    case 'stopmotion':
      return 'Stop-Motion';
    case 'theater':
      return 'Theater';
    default:
      break;
  }
};

const ProjectsPage = ({ data }) => {
  const { language, theme, toggleTheme } = useContext(LayoutContext);

  useEffect(() => {
    navigate(language === 'en' ? '/projects' : '/de/projects', {
      replace: true,
    });
  }, [language]);

  const {
    title,
    projects,
    firstPreviewImage,
    secondPreviewImage,
  } = data.projects.nodes[0];

  const { illustration4 } = data.overlay.nodes[0];

  const [activeTypes, setActiveTypes] = useState([]);
  const [availableTypes, setAvailableTypes] = useState([]);

  useEffect(() => {
    const allGivenTypes = projects
      .map((project) => project.projectType.type)
      .reduce((acc, val) => acc.concat(val), []);

    setAvailableTypes(
      allGivenTypes.filter((tag, i) => allGivenTypes.indexOf(tag) === i)
    );
  }, [projects]);

  return (
    <>
      <SEO siteTitle="Projects" />
      <StyledProjects className="spacing-inner">
        <div className="preview">
          <figure className="first">
            <Img
              fluid={firstPreviewImage.image?.asset.fluid}
              alt={firstPreviewImage?.alt}
            />
          </figure>
          <figure className="second">
            <Img
              fluid={secondPreviewImage.image.asset.fluid}
              alt={secondPreviewImage.alt}
            />
          </figure>
        </div>
        <div className="content">
          <p className="title">{title[language] || title.en}</p>
          <div className="projects">
            <AnimatePresence exitBeforeEnter>
              {projects
                .slice(0)
                .reverse()
                .map(
                  (project, i) =>
                    activeTypes.every((active) =>
                      project.projectType.type.includes(active)
                    ) && (
                      <motion.div
                        key={`key${i}`}
                        initial={{ filter: 'blur(10px)' }}
                        animate={{ filter: 'blur(0px)' }}
                        exit={{ filter: 'blur(10px)' }}
                        transition={{ duration: 0.2, ease: ease.smoothInOut }}
                      >
                        <ProjectPrev
                          key={i}
                          title={project.title}
                          src={project.previewImage.image.asset.fluid}
                          slug={
                            language === 'en'
                              ? `projects/${project.slug.current}`
                              : `de/projects/${project.slug.current}`
                          }
                          style={{
                            fontFamily: isEven(i)
                              ? 'var(--font-2)'
                              : 'var(--font-3)',
                          }}
                        />
                      </motion.div>
                    )
                )}
            </AnimatePresence>
          </div>
        </div>
        <div className="copy-small">
          <div className="types">
            {availableTypes.map((type, i) => (
              <button
                key={i}
                type="button"
                onClick={() =>
                  activeTypes.includes(type)
                    ? setActiveTypes(activeTypes.filter((e) => e !== type))
                    : setActiveTypes([...activeTypes, type])
                }
                style={{
                  textDecoration: activeTypes.includes(type) && 'underline',
                }}
              >
                {convertTypes(type)}
              </button>
            ))}
          </div>
        </div>
      </StyledProjects>
      {theme === 'light' && (
        <StyledOverlays onClick={toggleTheme} className="overlay">
          <Fade className="overlay4" active={theme === 'light'}>
            <Float speed={20} duration={1.6}>
              <Img
                fluid={illustration4.image.asset.fluid}
                alt={illustration4.alt}
              />
            </Float>
          </Fade>
        </StyledOverlays>
      )}
    </>
  );
};

const StyledProjects = styled.section`
  margin-top: var(--v-spacing-S);
  min-height: 100vh;
  overflow: hidden;

  .types {
    display: none;
  }

  .preview {
    padding-top: var(--spacing-M);
    display: flex;
    width: 100%;
    height: 100%;

    .first {
      width: 66%;
      height: 100%;
      padding-right: var(--spacing-XXS);
    }
    .second {
      width: 34%;
      height: 100%;
    }
  }

  .content {
    display: flex;
    padding-top: var(--spacing-XS);
    padding-bottom: var(--spacing-S);
    flex-direction: column;

    .title {
      font-family: var(--font-1);
      width: 33%;
      padding-bottom: var(--v-spacing-M);
    }

    .projects {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;

      h2 {
        font-size: var(--headline);
        width: 66%;
      }

      .preview-img {
        position: absolute;
        width: 500px;
        height: 500px;
        pointer-events: none;
      }
    }
  }

  @media ${media.M} {
    min-height: 100vh;
    position: relative;

    .content {
      flex-direction: row;
      padding-top: var(--v-spacing-M);
      padding-bottom: var(--spacing-XXL);
    }

    .preview {
      padding-top: 0;

      .first {
        width: 33%;
      }
      .second {
        width: 12%;
      }
    }

    .types {
      display: block;
      font-family: var(--font-3);
      border-top: var(--border);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5vw;
      background: var(--bg-color);
      z-index: 3;

      button {
        text-align: left;
        padding-left: var(--v-spacing-S);
        height: 100%;
      }
    }
  }
`;

const StyledOverlays = styled.div`
  .overlay4 {
    width: 60%;
    transform: translate3d(-1vw, -50px, 0);
  }
`;

export const query = graphql`
  query Projects {
    projects: allSanityProjectsPage {
      nodes {
        title {
          de
          en
        }
        firstPreviewImage {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        secondPreviewImage {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        projects {
          slug {
            current
          }
          title
          projectType {
            type
          }
          previewImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    overlay: allSanityOverlay {
      nodes {
        illustration4 {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

ProjectsPage.propTypes = {
  data: PropTypes.object,
};

export default ProjectsPage;
