import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, navigate } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { useConsole } from '../hooks/useConsole';
import SEO from '../components/organisms/seo';
import RichText from '../components/atoms/richtext';
import { LayoutContext } from '../components/organisms/layoutProvider';
import Logo from '../assets/svgs/logo.svg';
import { media } from '../utils/media-queries';
import ProjectPrevSmall from '../components/atoms/projectPrevSmall';
import PostPrev from '../components/atoms/postPrev';
import Fade from '../components/atoms/fade';
import Float from '../components/atoms/float';

const IndexPage = ({ data }) => {
  useConsole();
  const { language, theme, toggleTheme, setTheme } = useContext(LayoutContext);
  const raw = language === 'en' ? '_rawEn' : '_rawDe';

  useEffect(() => {
    navigate(language === 'en' ? '/' : '/de/', { replace: true });

    const showOverlay = setTimeout(() => setTheme('light'), 1000);
    const hideOverlay = setTimeout(() => setTheme('dark'), 2500);
    return () => clearTimeout(showOverlay, hideOverlay);
  }, [language, setTheme]);

  const {
    projectTitle,
    projectPreview,
    blogTitle,
    blogPreview,
    aboutTitle,
    aboutPreview,
    description,
    dateFrom,
    dateTo,
    projects,
    posts,
  } = data.homepage.nodes[0];

  const {
    illustration1,
    illustration2,
    illustration3,
    illustration5,
  } = data.overlay.nodes[0];

  return (
    <>
      <SEO />
      <StyledLogo>
        <h1>Claudia Six</h1>
        <Logo style={{ marginRight: '8px' }} />
        <Logo style={{ marginRight: '8px' }} />
        <Fade className="overlay" active={theme === 'light'}>
          <Img
            fluid={illustration1.image.asset.fluid}
            alt={illustration1.alt}
          />
        </Fade>
        <Logo style={{ zIndex: 1 }} />
      </StyledLogo>
      <StyledShortcuts className="spacing-inner">
        <div className="projects hover">
          <Link to={language === 'en' ? '/projects' : '/de/projects'}>
            <h2>{projectTitle[language]}</h2>
            <Img
              fluid={projectPreview.image.asset.fluid}
              alt={projectPreview.image.alt}
            />
          </Link>
        </div>
        <div className="blog hover">
          <Link to={language === 'en' ? '/blog' : '/de/blog'}>
            <h2>{blogTitle[language] || blogTitle.en}</h2>
            <Img
              fluid={blogPreview.image.asset.fluid}
              alt={blogPreview.image.alt}
            />
          </Link>
        </div>
        <div className="hover">
          <Link to={language === 'en' ? '/about' : '/de/about'}>
            <h2>{aboutTitle[language] || aboutTitle.en}</h2>
            <Img
              fluid={aboutPreview.image.asset.fluid}
              alt={aboutPreview.image.alt}
            />
          </Link>
        </div>
      </StyledShortcuts>
      <StyledDescription className="spacing-inner">
        <RichText blocks={description[raw]} />
      </StyledDescription>
      <StyledProjects>
        <div className="text">
          <p>{projectTitle[language]}</p>
          <p className="headline">{`${dateFrom} ᐸ ᐳ ${dateTo}`}</p>
        </div>
        <div className="slider">
          <div className="wrapper">
            {projects
              .slice(0)
              .reverse()
              .map((project, i) => (
                <ProjectPrevSmall
                  key={i}
                  slug={
                    language === 'en'
                      ? `projects/${project.slug.current}`
                      : `de/projects/${project.slug.current}`
                  }
                  src={project.previewImage.image.asset.fluid}
                  alt={project.previewImage.alt}
                  title={project.title}
                  types={project.projectType?.type}
                  year={project.year}
                />
              ))}
          </div>
        </div>
      </StyledProjects>
      <StyledBlog className="spacing-inner">
        <p className="title">{blogTitle[language] || blogTitle.en}</p>
        {posts
          .slice(0)
          .reverse()
          .map((post, i) => (
            <PostPrev
              key={i}
              slug={
                language === 'en'
                  ? `blog/${post.slug.current}`
                  : `de/blog/${post.slug.current}`
              }
              src={post.previewImage.image.asset.fluid}
              alt={post.previewImage.alt}
              title={post.title[language] || post.title.en}
              year={
                post.publishedAt &&
                (post.publishedAt[language] || post.publishedAt.en)
              }
            />
          ))}
      </StyledBlog>
      {theme === 'light' && (
        <StyledOverlays onClick={toggleTheme} className="overlay">
          <Fade className="overlay1" active={theme === 'light'}>
            <Float speed={25} duration={6}>
              <Img
                fluid={illustration3.image.asset.fluid}
                alt={illustration3.alt}
              />
            </Float>
          </Fade>
          <Fade className="overlay2" active={theme === 'light'}>
            <Float duration={8} speed={100}>
              <Img
                fluid={illustration5.image.asset.fluid}
                alt={illustration5.alt}
              />
            </Float>
            <Fade className="overlay3" active={theme === 'light'}>
              <Float speed={15}>
                <Img
                  fluid={illustration2.image.asset.fluid}
                  alt={illustration2.alt}
                />
              </Float>
            </Fade>
          </Fade>
        </StyledOverlays>
      )}
    </>
  );
};

const StyledLogo = styled.section`
  overflow-x: hidden;
  height: 87vh;
  display: flex;
  align-items: center;
  background: var(--text-color);
  padding: var(--v-spacing-XL) var(--v-spacing-S);

  h1 {
    display: none;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 87vh;
    left: 0;
  }

  svg {
    width: 100vw;
    height: 100%;

    path {
      fill: var(--bg-color);
      width: 50%;
    }
  }

  @media ${media.M} {
    padding: var(--v-spacing-S);

    .overlay {
      width: 50%;
      left: inherit;
      right: var(--spacing-XXL);
    }
  }
`;

const StyledShortcuts = styled.section`
  margin-top: var(--spacing-XS);
  display: flex;
  flex-direction: column;

  h2 {
    text-align: center;
    padding-bottom: var(--spacing-S);
  }

  div {
    width: 100%;
    padding-bottom: var(--spacing-S);

    div {
      border: var(--border);
      height: 50vw;
    }
  }

  @media ${media.M} {
    flex-direction: row;
    margin-top: var(--spacing-XL);

    .projects,
    .blog {
      padding-right: var(--v-spacing-S);
    }
  }
`;

const StyledDescription = styled.section`
  margin-top: var(--v-spacing-M);
  margin-bottom: var(--v-spacing-XL);
`;

const StyledProjects = styled.section`
  padding: var(--v-spacing-S) 0 var(--v-spacing-S) var(--v-spacing-S);

  .headline {
    padding-bottom: var(--spacing-XXS);
  }

  @media ${media.L} {
    display: flex;

    .text {
      width: 30%;
    }

    .slider {
      width: 70%;
      height: 65vh;
      overflow-x: scroll;
      overflow-y: hidden;

      .wrapper {
        width: 100%;
        height: 65vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
      }
    }
  }
`;

const StyledBlog = styled.section`
  margin-bottom: var(--v-spacing-S);

  .title {
    padding-bottom: var(--spacing-XS);
  }
`;

const StyledOverlays = styled.div`
  padding-top: 100vh;
  overflow: visible;

  .overlay1 {
    width: 90%;
    z-index: 10;
  }

  .overlay2 {
    width: 60%;
    position: relative;

    .gatsby-image-wrapper {
      transform: scaleX(-1);
    }
  }

  .overlay3 {
    width: 80%;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate3d(70%, 20%, 0);

    .gatsby-image-wrapper {
      transform: scaleX(1);
    }
  }
`;

export const query = graphql`
  query Homepage {
    homepage: allSanityHomepage {
      nodes {
        projectTitle {
          de
          en
        }
        projectPreview {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        blogTitle {
          de
          en
        }
        blogPreview {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        aboutTitle {
          de
          en
        }
        aboutPreview {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        description {
          _rawDe
          _rawEn
        }
        dateFrom
        dateTo
        projects {
          previewImage {
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
            alt
          }
          slug {
            current
          }
          title
          year
          projectType {
            type
          }
        }
        posts {
          slug {
            current
          }
          title {
            de
            en
          }
          publishedAt {
            de
            en
          }
          previewImage {
            alt
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    overlay: allSanityOverlay {
      nodes {
        illustration1 {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        illustration2 {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        illustration3 {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        illustration5 {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
