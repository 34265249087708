import { useEffect, useState } from 'react';
import { useClientSide } from './useClientSide';

export const useMouseMove = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [movement, setMovement] = useState({ x: 0, y: 0 });
  const [direction, setDirection] = useState('');
  const isClient = useClientSide;

  useEffect(() => {
    let prevX = 0;
    let currEvent;
    let prevEvent;

    const mouseEvent = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
      setDirection(e.pageX < prevX ? 'left' : 'right');
      prevX = e.pageX;
      currEvent = e;

      if (prevEvent && currEvent) {
        setMovement({
          x: Math.abs(currEvent.screenX - prevEvent.screenX),
          y: Math.abs(currEvent.screenY - prevEvent.screenY),
        });
      }
      prevEvent = currEvent;
    };

    if (isClient) window.addEventListener('mousemove', mouseEvent);

    return () => {
      if (isClient) window.removeEventListener('mousemove', mouseEvent);
    };
  }, [isClient]);

  return { position, movement, direction };
};
