import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { media } from '../../utils/media-queries';
import { convertTypes } from '../../pages/projects';

const ProjectPrevSmall = ({ slug, src, title, types, year, alt }) => (
  <Link to={`/${slug}`}>
    <StyledProjectPrevSmall className="hover">
      <Img fluid={src} alt={alt} />
      <div className="text copy-small">
        <h3>{title}</h3>
        {types?.map((type, i) => (
          <p key={i}>{convertTypes(type)}</p>
        ))}
        <p>{year}</p>
      </div>
    </StyledProjectPrevSmall>
  </Link>
);

const StyledProjectPrevSmall = styled.div`
  display: flex;
  padding-bottom: var(--spacing-XS);

  .gatsby-image-wrapper {
    border: var(--border-small);
    width: 60%;
    margin-right: var(--spacing-XXS);
  }

  h3 {
    font-family: var(--font-1);
    font-weight: 600;
  }

  p {
    font-family: var(--font-3);
  }

  @media ${media.L} {
    height: 20vh;
    width: 30vw;
    padding-bottom: var(--spacing-XXS);

    .gatsby-image-wrapper {
      width: 60%;
    }
  }
`;

ProjectPrevSmall.propTypes = {
  src: PropTypes.object,
  slug: PropTypes.string,
  title: PropTypes.string,
  year: PropTypes.string,
  types: PropTypes.array,
  alt: PropTypes.string,
};

export default ProjectPrevSmall;
